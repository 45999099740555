<template>
    <div class="home-app-bar">
        <v-app-bar app elevation="1" height="80">
            <router-link to="/">
                <img
                    data-cy="getac-logo"
                    :src="logoUrl"
                    class="getac-nav-logo"
                    @click="
                        clearActive = true
                        isHome = true
                    "
                />
            </router-link>
            <v-spacer />

            <div>
                <v-tabs background-color="transparent" class="hidden-sm-and-down" optional>
                    <v-tab
                        v-for="(navItem, i) in navigationItems"
                        :key="i"
                        :href="navItem.override"
                        :target="navItem.override && '_blank'"
                        :exact="false"
                        :ripple="false"
                        class="font-weight-regular nav-font"
                        :style="clearActive ? '' : activeItem(navItem.name)"
                        :class="{ initial: isHome }"
                        min-width="96"
                        :data-cy="navItem.dataCy"
                        hide-slider
                        text
                        @click="goTo(navItem)"
                    >
                        {{ navItem.title }}
                    </v-tab>

                    <v-menu v-if="$auth.isAuthenticated" open-on-hover bottom offset-y close-delay="250">
                        <template v-slot:activator="{ on, attrs }">
                            <v-tab
                                data-cy="signed-in-tab"
                                :to="{ name: 'your-account' }"
                                :exact="false"
                                :ripple="false"
                                class="font-weight-regular nav-font"
                                min-width="96"
                                hide-slider
                                text
                                v-bind="attrs"
                                v-on="on"
                                @click="clearActive = true"
                            >
                                Your Account
                            </v-tab>
                        </template>

                        <v-list>
                            <v-list-item v-if="hasGssOrgId" align="start" @click="yourDashboard">
                                <v-list-item-title class="nav-font">Dashboard</v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                v-for="(item, index) in myAccountDropDown"
                                :key="index"
                                align="start"
                                @click="item.method"
                            >
                                <v-list-item-title class="nav-font">{{ item.title }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                    <v-tab
                        v-else
                        data-cy="sign-in-tab"
                        :ripple="false"
                        class="font-weight-regular nav-font"
                        min-width="96"
                        hide-slider
                        text
                        @click="signIn"
                    >
                        Sign In
                    </v-tab>
                </v-tabs>
            </div>

            <v-app-bar-nav-icon class="hidden-md-and-up" @click="drawer = !drawer" />
        </v-app-bar>

        <home-drawer v-model="drawer" :items="drawerItems" :go-to="goTo" />
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import gql from 'graphql-tag'
import { rolesMap } from '@/config'

export default {
    name: `HomeAppBar`,

    components: {
        HomeDrawer: () => import(`./drawer`)
    },
    apollo: {
        queryOrg: {
            query: gql`
                query QueryOrg {
                    queryOrg {
                        gssOrgId
                        orgId
                        orgName
                    }
                }
            `,
            update(data) {
                if (data && data.queryOrg && data.queryOrg.gssOrgId) {
                    this.hasGssOrgId = true
                }
            }
        }
    },

    data() {
        return {
            drawer: null,
            clearActive: false,
            path: this.$route.name,
            isHome: false,
            hasGssOrgId: false,
            logoUrl: process.env.VUE_APP_PUBLIC_ASSETS_URL + `/logos/getac-logo.svg`,
            items: [
                {
                    name: `create-rma`,
                    title: `Create RMA`,
                    dataCy: `nav-create-rma`
                },
                {
                    name: `parts-order`,
                    title: `Parts Order`,
                    dataCy: `nav-parts-order`
                },
                {
                    name: `parts-usage`,
                    title: `Parts Usage`,
                    dataCy: `nav-parts-usage`
                },
                {
                    name: `upgrade-device`,
                    title: `Upgrade Device`,
                    dataCy: `nav-parts-usage`
                },
                {
                    name: `help-support`,
                    title: `Support`,
                    dataCy: `nav-help-support`
                }
            ]
        }
    },

    computed: {
        ...mapGetters({
            cart: `cart/cart`
        }),
        videoSerialPermission() {
            return this.$auth.allRoles(rolesMap.videoSerialPermission)
        },
        partsOrderPermission() {
            const sub = this.$auth && this.$auth.user ? this.$auth.user.sub || '' : ''
            return sub && !sub.endsWith('ca')
        },
        myAccountDropDown() {
            const dropdowns = [
                { title: `Your RMAs`, method: this.yourRmas },
                { title: `Your Parts Orders`, method: this.yourPartsOrders },
                {
                    title: `Product Registration`,
                    method: this.productRegistration
                },
                {
                    title: `Drivers`,
                    method: this.drivers
                },
                {
                    title: 'Saved Quotes',
                    method: this.yourQuotes
                }
            ]
            if (this.$auth.userInOrganization()) {
                dropdowns.push({ title: `Branches`, method: this.branches })
            }
            dropdowns.push(
                { title: `Account Settings`, method: this.yourAccount },
                { title: `Sign Out`, method: this.signOut }
            )
            return dropdowns
        },
        navigationItems() {
            const navItems = [
                {
                    name: `create-rma`,
                    title: `Create RMA`,
                    dataCy: `nav-create-rma`
                }
            ]
            const video = {
                name: `video-parts-order`,
                title: `Video Parts Order`,
                dataCy: `nav-video-parts-order`
            }
            if (this.videoSerialPermission) {
                navItems.push(video)
            }
            const partsOrder = {
                name: `parts-order`,
                title: `Parts Order`,
                dataCy: `nav-parts-order`
            }
            if (this.partsOrderPermission) {
                navItems.push(partsOrder)
            }
            navItems.push(
                {
                    name: `parts-usage`,
                    title: `Parts Usage`,
                    dataCy: `nav-parts-usage`
                },
                {
                    name: `upgrade-device`,
                    title: `Upgrade Device`,
                    dataCy: `nav-parts-usage`
                },
                {
                    name: `help-support`,
                    title: `Support`,
                    dataCy: `nav-help-support`
                }
            )
            return navItems
        },
        drawerItems() {
            if (this.$auth.isAuthenticated)
                return [
                    ...this.items,
                    { name: `your-account`, title: `Your Account` },
                    { name: `sign-out`, title: `Sign Out` }
                ]
            else return [...this.items, { name: `sign-in`, title: `Sign In` }]
        }
    },

    created() {
        this.checkCart()
    },

    methods: {
        ...mapActions({
            remove: `cart/remove`
        }),
        checkCart() {
            if (this.cart.length > 0) {
                function addDays(current, days) {
                    const date = new Date(current)
                    date.setDate(date.getDate() + days)
                    return date
                }
                this.cart.forEach((e) => {
                    const date = new Date()
                    const expired = addDays(e.storeDate, 2)
                    if (date > expired) {
                        this.remove({
                            ...e
                        })
                    }
                })
            }
        },
        activeItem(v) {
            const path = this.$route.name
            const current = v
            if (path === current) {
                return 'color: #db5120'
            } else {
                return ''
            }
        },
        goTo(v) {
            this.clearActive = true
            this.isHome = false
            if (v.name === `create-rma` && this.$route.name === `create-rma`) {
                v.query = {
                    refresh: Date.now()
                }
                const answer = window.confirm(`Do you really want to leave? You have unsaved changes..`)
                if (!answer) {
                    return
                }
            }

            if (v.name === 'sign-in') return this.signIn()

            this.$router.push(v)
        },
        signIn() {
            this.$auth.loginWithRedirect()
        },

        signOut() {
            this.$auth.logout()
        },

        yourAccount() {
            this.$router.push({ name: `account-settings` })
        },

        yourDashboard() {
            this.$router.push({ name: `dashboard` })
        },

        productRegistration() {
            this.$router.push({ name: `product-registration` })
        },

        yourRmas() {
            this.$router.push({ name: `rma-history` })
        },

        branches() {
            this.$router.push({ name: `branches` })
        },

        yourPartsOrders() {
            this.$router.push({ name: `parts-orders-history` })
        },

        drivers() {
            this.$router.push({ name: `drivers` })
        },

        yourQuotes() {
            this.$router.push({ name: `quotes` })
        }

        // savedQuotes() {
        //     this.$router.push({ name: 'your-account/saved-quotes' })
        // }
    }
}
</script>

<style lang="scss">
.home-app-bar {
    .v-tabs-slider {
        margin: 0 auto;
        max-width: 0;
    }

    .getac-nav-logo {
        max-width: 128px;
        width: 100%;
    }
}
.initial {
    color: rgba(0, 0, 0, 0.54) !important;
}
</style>
